import classnames from "classnames";
import { useEffect, useState } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Connection, Transaction } from '@solana/web3.js';
import { getParsedNftAccountsByOwner } from '@nfteyez/sol-rayz'
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { getAtaForMint, unwrapTransaction } from './utils';
import { Selector } from './selector';
import { sortBy } from 'lodash'
import bs58 from 'bs58'
import {
  Container,
  Snackbar,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import CTAButton from "./CTAButton";

import { PublicKey } from "@solana/web3.js";

import toast from "react-hot-toast";
// import CountUp from "react-countup";

import axios from "axios";

import styles from './Home.module.scss'

const sleep = async (ms) => {
  return new Promise((r) => setTimeout(r, ms));
};

export default function Claim({ wlSettings, canClaim, tokenBalance, setTokenBalance }) {
  const wallet = useWallet();
  const connection = new Connection(process.env.REACT_APP_SOLANA_RPC_HOST);
  const [loading, setLoading] = useState(false);
  const [tokensToClaim, setTokensToClaim] = useState();
  const [modalShowing, setModalShowing] = useState(false);
  const [selected, setSelected] = useState(null);
  const [nfts, setNfts] = useState([])

  function onNftClick(item) {
    if (selected && selected.mint === item.mint) {
      return setSelected(null)
    }
    setSelected(item);
    setModalShowing(false)
  }

  function toggleModal(e) {
    setModalShowing(!modalShowing);
  }

  async function burn() {
    try {
      if (!selected) {
        throw new Error('Select an item to burn');
      }
      setLoading(true);

      const data = {
        publicKey: wallet?.publicKey,
        mint: selected.mint,
        type: wlSettings.type
      }

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/incinerator/${wlSettings.collection}/burn-claim`, data);

      let transaction = Transaction.from(bs58.decode(res.data.transaction))

      const signedTransaction = await wallet.signTransaction(transaction, connection);
      const isVerifiedSignature = signedTransaction.verifySignatures();

      if (!isVerifiedSignature) {
        throw new Error('Error signing transaction');
      }

      const rawTransaction = signedTransaction.serialize();

      const sig = await connection.sendRawTransaction(rawTransaction);

      await connection.confirmTransaction(sig);

      toast.success('Minting token received')
      setTokenBalance(tokenBalance + 1);
      setSelected(null)
      setNfts(nfts.filter(nft => nft.mint !== selected.mint))

    } catch (e) {
      let message = e?.response?.data?.message || e?.response?.data || e.message || 'Something went wrong';

      if (message.includes('Attempt to debit an account but found no record of a prior credit')) {
        message = 'Not enough SOL to create token account'
      }
      toast.error(`Claim Error: ${message}`);
    } finally {
      setLoading(false);
    }
  }

  async function getTokensToClaim() {
    try {
      setLoading(true);

      const options = {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_SECRET_KEY}`
        }
      }

      const res = await axios.get(`${process.env.REACT_APP_API_URL}/claim/${wlSettings.table}/get-claimable-tokens/${wallet.publicKey}`, options);
      const { tokens } = res.data

      setLoading(false);

      if (res.status === 200) {
        setTokensToClaim(tokens);
      }
    } catch (err) {
      setLoading(false);
      toast.error("Error getting token balance");
    }
  }

  async function updateNfts() {
    const mints = (
      await axios.get(`${process.env.REACT_APP_API_URL}/info/${wlSettings.collection}/mints/${wlSettings.type}`)
    ).data

    const connection = new Connection(process.env.REACT_APP_SOLANA_RPC_HOST);
    const nfts = await getParsedNftAccountsByOwner({
      publicAddress: wallet?.publicKey?.toString(),
      connection
    })

    const promises = sortBy(
      nfts.filter(n => mints.includes(n.mint)
    ), ['mint'])
      .map(async item => {
        const { data: meta } = await axios.get(item.data.uri);
        return {
          ...item,
          image: meta.image
        }
      })

    const fetched = await Promise.all(promises);

    setNfts(fetched);
  }

  useEffect(() => {
    if (!wallet.publicKey) {
      return;
    }
    if (!wlSettings.burnMint) {
      getTokensToClaim();
    } else {
      updateNfts();
    }
  }, [wallet.publicKey, wlSettings]);

  async function claim() {
    if (!canClaim) {
      return false
    }
    try {
      setLoading(true);

      const options = {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_SECRET_KEY}`
        }
      }

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/claim/${wlSettings.table}/get-transaction`, { publicKey: wallet.publicKey, wlToken: true }, options);

      let { transaction } = res.data;

      transaction = unwrapTransaction(transaction);
      const signedTransaction = await wallet.signTransaction(transaction, connection);
      const isVerifiedSignature = signedTransaction.verifySignatures();

      if (!isVerifiedSignature) {
        throw new Error('Error signing transaction');
      }

      const rawTransaction = signedTransaction.serialize();

      const data = {
        rawTransaction,
        publicKey: wallet.publicKey
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/claim/${wlSettings.table}/send`, data, options);

      toast.success('Claim successful!');
      setLoading(false);
      setTokenBalance(tokenBalance + tokensToClaim);
      setTokensToClaim(0);

    } catch (e) {
      let message = e?.response?.data?.message || e?.response?.data || e.message || 'Something went wrong';

      if (message.includes('Timeout waiting for confirmation.')) {
        setTokensToClaim(0);
      }

      if (message.includes('Attempt to debit an account but found no record of a prior credit')) {
        message = 'Not enough SOL to create token account'
      }
      toast.error(`Claim Error: ${message}`);
      setLoading(false);
    }
  }

  return (
    <Container>
      {
        modalShowing && (
          <div className={styles.modal}>
            <div className={styles.modalinner}>
              <span className={styles.close} onClick={() => setModalShowing(false)}>&times;</span>
              <div className={styles.nftwrap}>
                <div className={styles.nftpannel}>
                  <Selector
                    items={nfts}
                    updateSelected={onNftClick}
                    side="left"
                    selected={selected}
                    isConnected={wallet.connected}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }
      <Container maxWidth="xs">
        {
          wlSettings.burnMint
          ? (
            <Paper
              style={{
                background: "rgba(0, 0, 0, 0.4)",
                borderStyle: "solid",
                borderColor: "#EDBE6E",
                boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.2)",
                backdropFilter: "blur(8px)",
                borderRadius: "20px",
                border: "5px solid #FFFFFF"
              }}
            >
              {
                selected
                  ? <img
                    onClick={toggleModal}
                    src={`https://cdn.magiceden.io/rs:fill:400:400:0:0/plain/${selected.image}`}
                  />
                  : (
                    <div className='burnitem'>
                      <a href="#" onClick={toggleModal}>Select NFT to Burn</a>
                    </div>
                  )
              }

              <CTAButton
                disabled={loading || !selected}
                onClick={burn}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  "Burn"
                )}
              </CTAButton>
            </Paper>
          )
          : (
            <Paper>
              <main>

                {wallet.connected && (
                  <div>
                    <h2>{wlSettings.title}</h2>
                    <h3>
                      Minting tokens to claim: { tokensToClaim }
                    </h3>
                    <h3>Minting token balance: {tokenBalance}</h3>
                    {
                      !canClaim && <p>Minting token claim opens 15 mins before minting round</p>
                    }
                    <CTAButton
                      disabled={loading || tokensToClaim === 0 || !canClaim}
                      onClick={claim}
                    >
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        "Claim"
                      )}
                    </CTAButton>
                  </div>
                )}

              </main>
            </Paper>
          )
        }
      </Container>
    </Container>
  );
}
