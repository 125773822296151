import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import uniq from 'lodash/uniq';
import partition from 'lodash/partition';
import pickBy from 'lodash/pickBy';
import classnames from 'classnames';
import { useState } from 'react';

import styles from './selector.module.scss';

export const Selector = ({
  updateSelected,
  items,
  side,
  isConnected,
  onStake,
  onClaim,
  loading,
  group,
  automatch,
  selected,
  selectingGroup,
  toggleSelectingGroup,
  tokensToClaim,
  selectedGroup,
  setSelectedGroup,
  toggleSelectedGroup,
}) => {

  const [adults, babies] = partition(items, item => item.type === 'adults')

  return (
    <div className={classnames(styles.nftwrap)}>
      <div className={classnames(styles.nftpanel, { [styles.loading]: loading, [styles.groupContainer]: group })}>
        {
          isConnected
            ? (
              <>
                {
                  items.length
                    ? (
                      items.map(item => {
                        const isSelected = selected?.mint === item.mint
                        return (
                          <div key={item.mint} className={classnames(styles['image-wrapper'], { [styles.selected]: isSelected })}>
                            {
                              isSelected && <div className={styles.selected} onClick={() => updateSelected && updateSelected(item)} />
                            }
                            <img
                              onClick={() => !loading && updateSelected && updateSelected(item)}
                              src={`https://cdn.magiceden.io/rs:fill:400:400:0:0/plain/${item.image}`}
                            />
                          </div>
                        )
                      })
                    )
                    : <div className={styles.noNfts}>
                      <h3>No eligible NFTs found</h3>
                      <a href="https://magiceden.io/marketplace/cybervillainz" target="_blank" noreferrer noopener>Buy on Magic Eden</a>
                    </div>

                }
              </>
            )
            : <WalletMultiButton />
        }
      </div>
    </div>
  )
}

export default Selector;
